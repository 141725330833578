.nav-bar {
    -webkit-box-shadow: 0px 8px 10px -4px rgba(0,0,0,0.25); 
    box-shadow: 0px 8px 10px -4px rgba(0,0,0,0.25);
}

.nav-fill {
    flex-grow: 1;
}

.nav-button {
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    padding-top: 7px;
    margin-top: 6px;
}

.nav-logo {
    margin-top: 15px;
    width: 120px;
    height: auto;
}

.nav-sync {}
.nav-address {}
.nav-userLogo {}


@media screen and (max-width: 912px) {
    .nav-logo-container {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;;
    }

    .nav-logo-container img{
        width: 120px;
        height: auto;
    }

    .menu-navbar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .nav-button, .nav-button-selected {
        font-size: 18px;
    }
    .nav-sync {
        padding-left: 20px;
        padding-right: 20px;
    }
    .nav-address {
        font-size: 18px;
    }

}

@media screen and (max-width: 468px) {
    .nav-logo-container {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;;
    }

    .nav-logo-container img{
        width: 100px;
        height: auto;
    }
    .nav-button, .nav-button-selected {
        font-size: 13px;
        margin-right: 0;
    }
    .nav-sync {
    }
   
    .div-sync {
        margin-left: 0px;
        padding-left: 7px;
    }
    .MuiToolbar-root {
        padding: 0;
    }
    .nav-address {
        font-size: 13px;
    }

}
