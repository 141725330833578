.App-body {
    min-height: 100vh;
    min-height: 100%;



    text-align: left;
    flex-direction: column;
}

.App-header {
    display: flex;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}