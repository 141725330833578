.MuiTypography-body1 {
    text-align: 'left'
}

.MuiButton-root {
    color: #303030;
    margin-right: 10px;
   border-color: #ffffff;
    border-style: solid;
    border-radius: 0px;
}

.nav-button:hover {
    border-width: 1px;
    border-color: #303030;
    border-style: solid;
    border-radius: 0px;
    background-color: #fff;
    margin-right: 10px;
    margin-top: 6px;
}

.nav-button-selected {
    border-width: 1px;
    border-color: #303030;
    border-style: solid;
    border-radius: 0px;
    background-color: #fff;
    margin-right: 10px;
    margin-top: 6px;
}

.nav-sync {
    background-color: #303030;
    border-radius: 25px;
    color: #fff;
    border-width: 0px;
}

.div-sync {
    border-left: #30303041;
    border-left-width: 1px;
    margin-left: 10px;
    padding-left: 20px;
}

.nav-sync:hover {
    background-color: #303030ec;
    border-radius: 25px;
    color: #fff;
    border-width: 0px;
}

.MuiButton-root {
    text-transform: uppercase;
}

.MuiList-root {
    background-color: #fff;
}

.nav-userMenu {
    margin-top: 47px;
}

.MuiMenuItem-root {
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 15px;
    margin-right: 15px;
}

.MuiMenuItem-root:hover {
    background-color: #fff;
    color: #005265;
}


.home-header {
    background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.7), rgba(48, 48, 48, 0.7)), url("/home/giga/workbench/POA/client/src/assets/img-header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 200px; 
    display: flex;
    justify-content: center;
    align-items: center;

}

.home-header h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 30px;   
    line-height: 40px;
    text-align: center;
}

.home-highlight {
    margin-top: 45px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.testo-home {
    text-align: justify;
}

.paginazione {
    font-size: 14px;
    margin-top: 22px;
}

.button-card-left, .button-card-right{
    color: #005265;
    border-style: solid;
    border-width: 1px;
    border-radius: 0px;
    border-color: #005265;
    margin-top: 13px;
}

.button-card-left:hover, .button-card-right:hover{
    background-color: #303030;
    border-style: solid;
    border-width: 1px;
    border-radius: 0px;
    border-color: #303030;
    color: #fff;
}

.button-card{
    color: #005265;
    border-width: 1px;
    border-color: #005265;
    border-style: solid;
    border-radius: 0px;
    margin-left: 20px;
    margin-bottom: 20px;
    border-radius: 25px;
}

.button-card:hover{
    border-width: 1px;
    border-color: #005265;
    border-style: solid;
    border-radius: 0px;
    background-color: #005265;
    color: #fff;
    border-radius: 25px;
}

.titolo-opera-card-highlight {
    color: #005265;
    font-size: 22px;
    text-decoration: none;
    font-weight: 700;
    text-decoration: none;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 5px;;
}

.nome-artista-card-highlight {
    color: #005265;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;;
}

.titolo-opera {
    font-size: 22px;
    color: #005265;
    font-weight: 700;
    margin-bottom: 5px;

}

.nome-artista {
    font-size: 15px;
    color: #005265;
    margin-bottom: 20px;
}

.countdown {
    font-size: 15px;
    color: #005265;
    margin-bottom: 10px;
    font-weight: 700;
    margin-bottom: 20px;
}


.button-card-piccole {
    color: #005265;
    border-width: 1px;
    border-color: #005265;
    border-style: solid;
    border-radius: 25px;
}

.button-card-piccole:hover {
    border-width: 1px;
    border-color: #005265;
    border-style: solid;
    border-radius: 0px;
    background-color: #005265;
    color: #fff;
    border-radius: 25px;
}


.titolo-evidenziato {
    background-color: #005265;
    padding: 1px;
    margin-bottom: 20px;

}

.titolo-evidenziato h1 {
    color: #fff;
    padding-left: 20px;
    border: 1px solid #fff;
    margin: 5px;
    font-weight: 300;
}

/* .titolo-evidenziato {
    padding: 1px;
    margin-bottom: 20px;

}

.titolo-evidenziato h1 {
    color: #005265;
    padding-left: 20px;
    border: 3px solid #005265;
    margin: 5px;
    font-weight: 700;
} */


.social-row {
    margin-top: 10px;
}

.footer :hover{
    color: rgba(255, 255, 255, 0.617);
}

.footer a{
    line-height: 28px;
}


.collection-main{
    margin-bottom: 25px   ;
    text-align: center;
}

.titolo-opera-interno {
    margin-bottom: 10px;
    font-size: 30px;
    color: #005265;
    font-weight: 700;
}

.autore {
    margin-bottom: 50px;
}

.desc-rand-image {
    font-weight: 400;
    font-size: 15px;
    font-style: italic;
}
.desc-rand-image-expain{
    font-weight: 400;
    font-size: 15px;
    font-style: italic;
}

.bottoni-interni {
    color: #005265;
    border-width: 1px;
    border-color: #005265;
    border-style: solid;
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.bottoni-interni:hover {
    border-width: 1px;
    border-color: #005265;
    border-style: solid;
    border-radius: 0px;
    background-color: #005265;
    color: #fff;
    border-radius: 25px;
}

.collection-description{
    margin-top: 35px;
}

.collection-description-text{
    margin-top: 30px;
}

.rarities {
    font-weight: 700;
}

.dati {
    font-size: 15px;
}

.collection-description-tags{
    margin-top: 30px;
    color: #005265;
}

.descrizione-interna {
    font-size: 16px;
    font-weight: 400;
}

.collection-gallery{
    margin-top: 55px;
    text-align: center;
}

.collection-gallery-imagelist {
    margin-top: 30px;
}

.collection-gallery-imagelist-collected img {
    overflow: hidden;
    max-height: 240px;
}

.collection-gallery-image {
    border: 2px solid #005265;
    padding: 20px;
    margin: 20px;
    overflow: hidden;

}

.titolo-sezione-minted {
    margin-bottom: 10px;
    font-size: 30px;
    color: #005265;
    font-weight: 700;
    margin-bottom: 55px;
}

.titolo-minted {
    font-weight: 700;
    font-size: 16px;
    color: #005265;
    margin-top: 15px;
}

.bottoni-interni-minted {
    color: #005265;
    border-width: 1px;
    border-color: #005265;
    border-style: solid;
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
}

.bottoni-interni-minted:hover {
    border-width: 1px;
    border-color: #005265;
    border-style: solid;
    border-radius: 0px;
    background-color: #005265;
    color: #fff;
    border-radius: 25px;
}

.bottoni-interni-disabled{
}

.collection-divider{
    border-color: #005265;
    border-width: 0.5px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.collection-divider-norarities {
    border-color: #005265;
    border-width: 0.5px;
    margin-bottom: 50px;
}

.paginazione-interna {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #111314;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
}

.paginazione-interna-active{
    pointer-events: none;

}

.paginazione-interna:hover, .paginazione-interna-active, .paginazione-interna-active:hover {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #005265;
    border-style: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #005265;
}

.paginazione-interna-disabled{
    color: rgba(0, 0, 0, 0.26);
    
}
.paginazione-interna-disabled:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.161);
    border-color: transparent;
}

.paginazione-interna-disabled:active {
    color: rgba(0, 0, 0, 0.26);
    border-style: solid;
    border-color: #005265;
    border-width: 1px;
}

.titolo-artists {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #005265;
}

.artists-nome-artista {
    font-size: 22px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #005265;
}

@media screen and (min-width: 913px) {
    .collection-main-img {
        image-rendering: -webkit-optimize-contrast;
        min-height: 90vh;
        max-height: 90vh;
        /*min-width: 100vh;*/
        justify-content: center;
        margin-bottom: 50px;
    }
    
    
    .collection-main-img img {
        image-rendering: -webkit-optimize-contrast;
        /*max-width: 100vh;*/
        max-height: 90vh;
        align-items: center;
        margin-top: 20px;
        max-width: 1150px;
    }

    .frame-collected {
        min-height: 250px;  
    }

    .collection-gallery-imagelist img {
        max-width: 128px;
    }

    .collection-gallery-imagelist img {
        overflow: hidden;
        max-height: 240px;
        max-width: 300px;
    }

    .collection-main-img-iframe {
        margin-bottom: 0;
    }

    .opera-iframe {
        margin-bottom: 30px;
/*         min-width: 100%;
        min-height: 65vh; */
    }
}


@media screen and (max-width: 912px) {

    .footer {
        text-align: center;
    }
    .claim-footer-1 {
        padding-left: 40px;
    }
    .claim-footer-2 {
        padding-left: 40px;
    }
    .social-row {
        padding-left: 40px;
    }

    .home-header h1 {
        font-size: 42px;
    }

    .collection-gallery-imagelist-collected img {
        overflow: hidden;
    }

    .collection-gallery-image {
        padding: 15px;
        margin: 20px;
    }

    caption {
        font-size: 15px;
    }
    .testo-home {
        margin-bottom: 50px;
        font-size: 20px;
    }

    .home-highlight h3 {
        font-size: 22px;
    }

    .collection-main-img img {
        max-width: 65vh;
        max-height: 65vh;
        /*margin-top: 50px;*/
        margin-bottom: 30px;
    }

    .opera-iframe {
        margin-bottom: 30px;
        min-width: 530px;
    }

    .frame-collected {
        min-height: 250px;
        
    }

    .collection-gallery-imagelist img {
        max-width: 180px;
    }

    .opera-iframe {
        margin-bottom: 30px;
        min-width: 100%;
        min-height: 65vh;
    }

}

@media screen and (max-width: 468px) {

    .claim-footer-1 {
        padding-left: 10px;
        font-size: 15px;
    }
    .claim-footer-2 {
        padding-left: 10px;
        font-size: 15px;
    }
    .social-row {
        padding-left: 10px;
        margin-top: 20px;
    }
    .footer {
        text-align: center;
    }
    .home-header h1 {
        font-size: 28px;
        line-height: 35px;
    }

    h1 {
        font-size: 25px;
    }
    .testo-home {
        margin-bottom: 50px;
    }

    .collection-main-img img {
        max-width: 49vh;
    }

    .collection-main-img img iframe {
        max-width: 45vh;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    
    .bottoni-interni {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .collection-gallery-imagelist-collected img {
        justify-content: center;
    }

    .collection-gallery-imagelist img {
        max-width: 268px;
    }

    .testo-home {
        font-size: 16px;
    }

    .frame-collected {
        min-height: 280px;  
    }

    .opera-iframe {
        margin-bottom: 30px;
        max-width: 280px;
        min-height: 65vh;
    }

    .titolo-evidenziato h1 {
        font-size: 20px;
    }
}


:fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  :fullscreen defaultCanvas0 {
    border: 1px solid rgb(1, 193, 167);
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }